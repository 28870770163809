import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby';

import { langPrefix, passLanguage, useLanguage } from '../components/language-context'
import { SubscribingPageLayout } from '../components/subscribing-page-layout'
import { RestService } from '../services/rest-service';
import styled from 'styled-components';

const Button = styled(Link)`
  display: flex;
  background: black;
  color: white;
  padding: 13px;
  border-radius: 4px;
  width: 120px;
  height: 42px;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: bold;
  align-self: center;
  line-height: 16px;
  margin-top: 40px;
`

const UnsubscribePageBase = useLanguage(({location, lang, msg}) => {

  const [email, setEmail] = useState("");

  useEffect(() => {
    const parameters = new URLSearchParams(location.search);
    const emailParameter = parameters.get("email");
    
    if (emailParameter) {
      setEmail(emailParameter);
      RestService.unsubscribe(emailParameter);
    }
  }, []);

  return (
    <SubscribingPageLayout title={msg('unsubscribe-title')} smiley={'red'}>
        <div>
          {msg('unsubscribe-p1-content')}
        </div>
        <div>
          {msg('unsubscribe-p2-content')}
        </div>
        <Button to={langPrefix(lang, `/resubscribe?email=${email}`)}>{msg('unsubscribe-p2-resubscribe-link')}</Button>
    </SubscribingPageLayout>
  )
});

const UnsubscribePage = passLanguage(UnsubscribePageBase);
export default UnsubscribePage;